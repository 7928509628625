import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CanadaRoutingModule } from './canada-routing.module';

import { InvestorVisaComponent } from './investor-visa/investor-visa.component';
import { OntarioImmigrantNomineeProgramComponent } from './country/ontario-immigrant-nominee-program/ontario-immigrant-nominee-program.component';
import { AlbertaImmigrantNomineeProgramComponent } from './country/alberta-immigrant-nominee-program/alberta-immigrant-nominee-program.component';
import { SaskatchewanImmigrantNomineeProgramComponent } from './country/saskatchewan-immigrant-nominee-program/saskatchewan-immigrant-nominee-program.component';
import { BritishColumbiaProvincialNomineeProgramComponent } from './country/british-columbia-provincial-nominee-program/british-columbia-provincial-nominee-program.component';
import { ProvincialNomineeProgramComponent } from './country/provincial-nominee-program/provincial-nominee-program.component';
import { NovaScotiaComponent } from './country/nova-scotia/nova-scotia.component';
import { ManitobaProvincialNomineeProgramComponent } from './country/manitoba-provincial-nominee-program/manitoba-provincial-nominee-program.component';
import { YukonProvincialNomineeProgramComponent } from './country/yukon-provincial-nominee-program/yukon-provincial-nominee-program.component';
import { RuralAndNorthernImmigrationPilotComponent } from './country/rural-and-northern-immigration-pilot/rural-and-northern-immigration-pilot.component';
import { PrinceEdwardIslandProvincialNomineeProgramComponent } from './country/prince-edward-island-provincial-nominee-program/prince-edward-island-provincial-nominee-program.component';
import { NewBrunswickProvincialNomineeProgramComponent } from './country/new-brunswick-provincial-nominee-program/new-brunswick-provincial-nominee-program.component';
import { NorthwestTerritoriesProvincialNomineeProgramComponent } from './country/northwest-territories-provincial-nominee-program/northwest-territories-provincial-nominee-program.component';



@NgModule({
  declarations: [ InvestorVisaComponent,  OntarioImmigrantNomineeProgramComponent, AlbertaImmigrantNomineeProgramComponent, SaskatchewanImmigrantNomineeProgramComponent, BritishColumbiaProvincialNomineeProgramComponent, ProvincialNomineeProgramComponent, NovaScotiaComponent, ManitobaProvincialNomineeProgramComponent, YukonProvincialNomineeProgramComponent, RuralAndNorthernImmigrationPilotComponent, PrinceEdwardIslandProvincialNomineeProgramComponent, NewBrunswickProvincialNomineeProgramComponent, NorthwestTerritoriesProvincialNomineeProgramComponent, ],
  imports: [
    CommonModule,
    CanadaRoutingModule
  ]
})
export class CanadaModule { }
