import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-yukon-provincial-nominee-program',
  templateUrl: './yukon-provincial-nominee-program.component.html',
  styleUrls: ['./yukon-provincial-nominee-program.component.css']
})
export class YukonProvincialNomineeProgramComponent implements OnInit {

  title='Yukon Provincial Nominee Program | YPNP | True Global'
  keyword='Yukon provincial nominee program, ypnp, Yukon nominee program, Yukon nominee program'
  description='The Yukon Provincial Nominee Program (Yukon PNP) helps all the skilled and semi-skilled aspirants receive a provincial nomination certificate.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'yukon-provincial-nominee-program',title:'Yukon Provincial Nominee Program | YPNP | True Global' })
    this.meta.addTag({name:'yukon-provincial-nominee-program', keyword:'Yukon provincial nominee program, ypnp, Yukon nominee program, Yukon nominee program' })
    this.meta.addTag({name:'yukon-provincial-nominee-program', description:'The Yukon Provincial Nominee Program (Yukon PNP) helps all the skilled and semi-skilled aspirants receive a provincial nomination certificate.'})
  }

}