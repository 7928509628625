import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-text',
  templateUrl: './content-text.component.html',
  styleUrls: ['./content-text.component.css']
})
export class ContentTextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
