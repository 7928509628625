import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-rural-and-northern-immigration-pilot',
  templateUrl: './rural-and-northern-immigration-pilot.component.html',
  styleUrls: ['./rural-and-northern-immigration-pilot.component.css']
})
export class RuralAndNorthernImmigrationPilotComponent implements OnInit {
 
    title='Rural and Northern immigration Pilot | RINP | True Global'
    keyword='Rural and northern immigration pilot, rinp, rural northern immigration pilot program, true global immigration'
    description='The Rural and Northern Immigration Pilot (RNIP) program is designed to help bring new skilled worker immigrants to small and remote communities'
    constructor(
      public router:Router,
      private titleService:Title,
      private meta:Meta,
    ) { }
  
    ngOnInit() {
  
      this.titleService.setTitle(this.title);
      this.meta.addTag({name:'rural-and-northern-immigration-pilot',title:'Rural and Northern immigration Pilot | RINP | True Global' })
      this.meta.addTag({name:'rural-and-northern-immigration-pilot', keyword:'Rural and northern immigration pilot, rinp, rural northern immigration pilot program, true global immigration' })
      this.meta.addTag({name:'rural-and-northern-immigration-pilot', description:'The Rural and Northern Immigration Pilot (RNIP) program is designed to help bring new skilled worker immigrants to small and remote communities'})
    }
  
  }
