import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-british-columbia-provincial-nominee-program',
  templateUrl: './british-columbia-provincial-nominee-program.component.html',
  styleUrls: ['./british-columbia-provincial-nominee-program.component.css']
})
export class BritishColumbiaProvincialNomineeProgramComponent implements OnInit {

  title='British Columbia Provincial Nominee Program | BC PNP Express Entry | True Global'
  keyword='British Columbia provincial nominee program, bc pnp express entry, bc canada, british Columbia'
  description='The BC Provincial Nominee Program (PNP) is the province"s only direct economic immigration program for bringing in new British Columbians.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'british-columbia-provincial-nominee-program',title:'British Columbia Provincial Nominee Program | BC PNP Express Entry | True Global' })
    this.meta.addTag({name:'british-columbia-provincial-nominee-program', keyword:'British Columbia provincial nominee program, bc pnp express entry, bc canada, british Columbia' })
    this.meta.addTag({name:'british-columbia-provincial-nominee-program', description:'The BC Provincial Nominee Program (PNP) is the province"s only direct economic immigration program for bringing in new British Columbians.'})
  }

}