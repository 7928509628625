import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visit-visa',
  templateUrl: './visit-visa.component.html',
  styleUrls: ['./visit-visa.component.css']
})
export class VisitVisaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
