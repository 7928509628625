import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-canada',
  templateUrl: './canada.component.html',
  styleUrls: ['./canada.component.css']
})
export class CanadaComponent implements OnInit {

  title ='Best Immigration Consultants for Canada |Canada Skilled Immigration| Migrate to Canada'
  keyword='best immigration consultants for Canada, migrate to Canada, Canada skilled immigration, Canada immigration'
  description='True Global Immigration is a renowned and best immigration consultants for Canada offering visa through Canada skilled immigration, so if you’re looking for migrate to Canada then call 9999999999.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'canada', title:  'Best Immigration Consultants for Canada |Canada Skilled Immigration| Migrate to Canada' })
    this.meta.addTag({name:'canada', description:'True Global Immigration is a renowned and best immigration consultants for Canada offering visa through Canada skilled immigration, so if you’re looking for migrate to Canada then call 9999999999. ' })
    this.meta.addTag({name:'canada', keyword:'best immigration consultants for Canada, migrate to Canada, Canada skilled immigration, Canada immigration'})
  }

}
