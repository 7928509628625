import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-universities',
  templateUrl: './top-universities.component.html',
  styleUrls: ['./top-universities.component.css']
})
export class TopUniversitiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
