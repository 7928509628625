import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-prince-edward-island-provincial-nominee-program',
  templateUrl: './prince-edward-island-provincial-nominee-program.component.html',
  styleUrls: ['./prince-edward-island-provincial-nominee-program.component.css']
})
export class PrinceEdwardIslandProvincialNomineeProgramComponent implements OnInit {

  title='Prince Edward Island PNP | PEI Express Entry | True Global'
  keyword='Prince Edward island pnp, pei express entry, provincial nominee program, prince Edward island immigration, prince Edward pnp'
  description='Immigrate to Canada through Prince Edward Island PNP Program. Get expert support processing time, eligibility, requirements, step-by-step process etc.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'prince-edward-island-provincial-nominee-program',title:'Prince Edward Island PNP | PEI Express Entry | True Global' })
    this.meta.addTag({name:'prince-edward-island-provincial-nominee-program', keyword:'Prince Edward island pnp, pei express entry, provincial nominee program, prince Edward island immigration, prince Edward pnp' })
    this.meta.addTag({name:'prince-edward-island-provincial-nominee-program', description:'Immigrate to Canada through Prince Edward Island PNP Program. Get expert support processing time, eligibility, requirements, step-by-step process etc.'})
  }

}
