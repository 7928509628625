import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-new-brunswick-provincial-nominee-program',
  templateUrl: './new-brunswick-provincial-nominee-program.component.html',
  styleUrls: ['./new-brunswick-provincial-nominee-program.component.css']
})
export class NewBrunswickProvincialNomineeProgramComponent implements OnInit {

  title='New Brunswick Provincial Nominee Program | New Brunswick PNP | True Global'
  keyword='New Brunswick provincial nominee program, new Brunswick pnp, new Brunswick immigration, new Brunswick express entry immigration, NBPNP'
  description='The New Brunswick Provincial Nominee Program is a provincial immigration program made to help the eligible candidate to be nominated by the province'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'new-brunswick-provincial-nominee-program',title:'New Brunswick Provincial Nominee Program | New Brunswick PNP | True Global' })
    this.meta.addTag({name:'new-brunswick-provincial-nominee-program', keyword:'New Brunswick provincial nominee program, new Brunswick pnp, new Brunswick immigration, new Brunswick express entry immigration, NBPNP' })
    this.meta.addTag({name:'new-brunswick-provincial-nominee-program', description:'The New Brunswick Provincial Nominee Program is a provincial immigration program made to help the eligible candidate to be nominated by the province'})
  }

}
