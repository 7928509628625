import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
@Component({
  selector: 'app-plan-life',
  templateUrl: './plan-life.component.html',
  styleUrls: ['./plan-life.component.css']
})
export class PlanLifeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
   
  

  }

}
