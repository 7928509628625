import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-study-in-canada',
  templateUrl: './study-in-canada.component.html',
  styleUrls: ['./study-in-canada.component.css']
})
export class StudyInCanadaComponent implements OnInit {

  title='The Education System in Canada – True Global'
  keyword='Study in canada, education system in canada, study in canada – free guidance & Counselling, study in canada as a international students'
  description='Apply to study in Canada as an international student, extend your study permit and find out about working while you study or after you graduate.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'study-in-canada',title:'The Education System in Canada – True Global' })
    this.meta.addTag({name:'study-in-canada', keyword:'Study in canada, education system in canada, study in canada – free guidance & Counselling, study in canada as a international students' })
    this.meta.addTag({name:'study-in-canada', description:'Apply to study in Canada as an international student, extend your study permit and find out about working while you study or after you graduate.'})
  }

}