import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';
import { RefundPolicyComponent } from './pages/refund-policy/refund-policy.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { CanadaComponent } from './canada.component';
import { VisitVisaComponent } from './visit-visa/visit-visa.component';
import { SuperVisaComponent } from './super-visa/super-visa.component';
import { StartupVisaComponent } from './startup-visa/startup-visa.component';
import { SettlementServiceComponent } from './settlement-service/settlement-service.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { WorkVisaComponent } from './work-visa/work-visa.component';
import { SponsorshipVisaCanadaComponent } from './sponsorship-visa-canada/sponsorship-visa-canada.component';
import { ExpressEntryComponent } from './express-entry/express-entry.component';

const routes: Routes = [
  // {path:'banner',component:BannerComponent},
  {path:'', redirectTo:'banner',pathMatch:'full'},
  {path:'sitemap',component:SitemapComponent},
  {path:'canada',component:CanadaComponent},
  {path:'contact',component:ContactComponent},
  {path:'about-us',component:AboutUsComponent},

  {path:'contact-us',component:ContactUsComponent},
  {path:'privacy-policy',component:PrivacyPolicyComponent},
  {path:'terms-condition',component:TermsConditionComponent},
  {path:'refund-policy',component:RefundPolicyComponent},
  {path:'disclaimer',component:DisclaimerComponent},
  {path:'work-visa',component:WorkVisaComponent},
  {path:'visit-visa',component:VisitVisaComponent},
  {path:'super-visa',component:SuperVisaComponent},
  {path:'startup-visa',component:StartupVisaComponent},
  {path:'settlement-service',component:SettlementServiceComponent},
  {path:'assessment',component:AssessmentComponent},
  {path:'sponsorship-visa-canada',component:SponsorshipVisaCanadaComponent},
  {path:'express-entry',component:ExpressEntryComponent},
  {path: 'australia', loadChildren: () => import(`./canada/canada.module`).then(m => m.CanadaModule) },
  {path: 'visa', loadChildren: () => import(`./visa/visa.module`).then(m => m.VisaModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
