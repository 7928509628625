import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-investor-visa',
  templateUrl: './investor-visa.component.html',
  styleUrls: ['./investor-visa.component.css']
})
export class InvestorVisaComponent implements OnInit {

  title='Canada Business Visa | Canada Investor Visa | True Global'
  keyword='Canada business visa, canada investor visa, canada business visa process, invest in canada, business visa for canada'
  description='Business Visa Consultants. Apply for Business visa programs through True Global Immigration in Canada.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'investor-visa',title:'Canada Business Visa | Canada Investor Visa | True Global' })
    this.meta.addTag({name:'investor-visa', keyword:'Canada business visa, canada investor visa, canada business visa process, invest in canada, business visa for canada' })
    this.meta.addTag({name:'investor-visa', description:'Business Visa Consultants. Apply for Business visa programs through True Global Immigration in Canada.'})
  }

}