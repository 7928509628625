import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvestorVisaComponent } from './investor-visa/investor-visa.component';
import { OntarioImmigrantNomineeProgramComponent } from './country/ontario-immigrant-nominee-program/ontario-immigrant-nominee-program.component';
import { AlbertaImmigrantNomineeProgramComponent } from './country/alberta-immigrant-nominee-program/alberta-immigrant-nominee-program.component';
import { SaskatchewanImmigrantNomineeProgramComponent } from './country/saskatchewan-immigrant-nominee-program/saskatchewan-immigrant-nominee-program.component';
import { BritishColumbiaProvincialNomineeProgramComponent } from './country/british-columbia-provincial-nominee-program/british-columbia-provincial-nominee-program.component';
import { ProvincialNomineeProgramComponent } from './country/provincial-nominee-program/provincial-nominee-program.component';
import { NovaScotiaComponent } from './country/nova-scotia/nova-scotia.component';
import { ManitobaProvincialNomineeProgramComponent } from './country/manitoba-provincial-nominee-program/manitoba-provincial-nominee-program.component';
import { YukonProvincialNomineeProgramComponent } from './country/yukon-provincial-nominee-program/yukon-provincial-nominee-program.component';
import { RuralAndNorthernImmigrationPilotComponent } from './country/rural-and-northern-immigration-pilot/rural-and-northern-immigration-pilot.component';
import { PrinceEdwardIslandProvincialNomineeProgramComponent } from './country/prince-edward-island-provincial-nominee-program/prince-edward-island-provincial-nominee-program.component';
import { NewBrunswickProvincialNomineeProgramComponent } from './country/new-brunswick-provincial-nominee-program/new-brunswick-provincial-nominee-program.component';
import { NorthwestTerritoriesProvincialNomineeProgramComponent } from './country/northwest-territories-provincial-nominee-program/northwest-territories-provincial-nominee-program.component';

const routes: Routes = [

  {path:'investor-visa',component:InvestorVisaComponent},
  {path:'country/ontario-immigrant-nominee-program',component:OntarioImmigrantNomineeProgramComponent},
  {path:'country/alberta-immigrant-nominee-program',component:AlbertaImmigrantNomineeProgramComponent},
  {path:'country/saskatchewan-immigrant-nominee-program',component:SaskatchewanImmigrantNomineeProgramComponent},
  {path:'country/british-columbia-provincial-nominee-program',component:BritishColumbiaProvincialNomineeProgramComponent},
  {path:'country/nova-scotia',component:NovaScotiaComponent},
  {path:'country/provincial-nominee-program',component:ProvincialNomineeProgramComponent},
  {path:'country/manitoba-provincial-nominee-program',component:ManitobaProvincialNomineeProgramComponent},
  {path:'country/yukon-provincial-nominee-program',component:YukonProvincialNomineeProgramComponent},
  {path:'country/rural-and-northern-immigration-pilot',component:RuralAndNorthernImmigrationPilotComponent},
  {path:'country/prince-edward-island-provincial-nominee-program',component:PrinceEdwardIslandProvincialNomineeProgramComponent},
  {path:'country/new-brunswick-provincial-nominee-program',component:NewBrunswickProvincialNomineeProgramComponent},
  {path:'country/northwest-territories-provincial-nominee-program',component:NorthwestTerritoriesProvincialNomineeProgramComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CanadaRoutingModule { }
