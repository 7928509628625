import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alberta-immigrant-nominee-program',
  templateUrl: './alberta-immigrant-nominee-program.component.html',
  styleUrls: ['./alberta-immigrant-nominee-program.component.css']
})
export class AlbertaImmigrantNomineeProgramComponent implements OnInit {

  title='Alberta Provincial Nominee Program | Alberta PNP | AINP'
  keyword='Alberta provincial nominee program, alberta pnp, ainp, alberta express entry, true global immigration'
  description='Immigrate to Canada through the Alberta Immigrant Nominee Program (AINP) offers a valuable way. AINP is an economic immigration program operated by the government.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {
    
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'alberta-immigrant-nominee-program',title:'Alberta Provincial Nominee Program | Alberta PNP | AINP' })
    this.meta.addTag({name:'alberta-immigrant-nominee-program', keyword:'Alberta provincial nominee program, alberta pnp, ainp, alberta express entry, true global immigration' })
    this.meta.addTag({name:'alberta-immigrant-nominee-program', description:'Immigrate to Canada through the Alberta Immigrant Nominee Program (AINP) offers a valuable way. AINP is an economic immigration program operated by the government.'})
  }

}