import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-express-entry',
  templateUrl: './express-entry.component.html',
  styleUrls: ['./express-entry.component.css']
})
export class ExpressEntryComponent implements OnInit {

  title ='Express Entry Canada Immigration – True Global'
  keyword='Express entry canada immigration, immigrate to canada, immigration advisor in canada, true global immigration, hire best immigration consultant in canada, immigrate through express entry, immigrate to canada with express entry'
  description='Express Entry is an electronic system used to manage the applications of skilled workers who wish to become permanent residents of Canada.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'Home', title:'Express Entry Canada Immigration – True Global' })
    this.meta.addTag({name:'Home', keyword:'Express entry canada immigration, immigrate to canada, immigration advisor in canada, true global immigration, hire best immigration consultant in canada, immigrate through express entry, immigrate to canada with express entry' })
    this.meta.addTag({name:'Home', description:'Express Entry is an electronic system used to manage the applications of skilled workers who wish to become permanent residents of Canada.'})
    // this.meta.updateTag({ property: 'og:url', content: `australia/australia-skilled-nominated-visa}` });
  }

}