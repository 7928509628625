import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manitoba-provincial-nominee-program',
  templateUrl: './manitoba-provincial-nominee-program.component.html',
  styleUrls: ['./manitoba-provincial-nominee-program.component.css']
})
export class ManitobaProvincialNomineeProgramComponent implements OnInit {

  title='Manitoba Provincial Nominee Program | Manitoba PNP | MPNP'
  keyword='Manitoba provincial nominee program, Manitoba pnp, mpnp, Manitoba express entry immigration, true global immigration'
  description='The Manitoba Provincial Nominee Program (MPNP) selects candidates for Manitoba immigration based on the province"s specific economic needs.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'manitoba-provincial-nominee-program',title:'Manitoba Provincial Nominee Program | Manitoba PNP | MPNP' })
    this.meta.addTag({name:'manitoba-provincial-nominee-program', keyword:'Manitoba provincial nominee program, Manitoba pnp, mpnp, Manitoba express entry immigration, true global immigration' })
    this.meta.addTag({name:'manitoba-provincial-nominee-program', description:'The Manitoba Provincial Nominee Program (MPNP) selects candidates for Manitoba immigration based on the province"s specific economic needs.'})
  }

}
