import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-saskatchewan-immigrant-nominee-program',
  templateUrl: './saskatchewan-immigrant-nominee-program.component.html',
  styleUrls: ['./saskatchewan-immigrant-nominee-program.component.css']
})
export class SaskatchewanImmigrantNomineeProgramComponent implements OnInit {

  title='Saskatchewan Immigration Nominee Program | Saskatchewan PNP | True Global'
  keyword='Saskatchewan immigration nominee program, Saskatchewan pnp, Saskatchewan immigration, Saskatchewan sinp, Saskatchewan express entry'
  description='The Saskatchewan Immigrant Nominee Program (SINP) provides pathways for immigration to Canada.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'saskatchewan-immigrant-nominee-program',title:'Saskatchewan Immigration Nominee Program | Saskatchewan PNP | True Global' })
    this.meta.addTag({name:'saskatchewan-immigrant-nominee-program', keyword:'Saskatchewan immigration nominee program, Saskatchewan pnp, Saskatchewan immigration, Saskatchewan sinp, Saskatchewan express entry' })
    this.meta.addTag({name:'saskatchewan-immigrant-nominee-program', description:'The Saskatchewan Immigrant Nominee Program (SINP) provides pathways for immigration to Canada.'})
  }

}