import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-nova-scotia',
  templateUrl: './nova-scotia.component.html',
  styleUrls: ['./nova-scotia.component.css']
})
export class NovaScotiaComponent implements OnInit {

  title='Nova Scotia Provincial Nominee Program | Nova Scotia PNP | True Global'
  keyword='Nova scotia provincial nominee program , nova scotia pnp, nova scotia immigration, nova scotia express entry, immigrate to nova scotia'
  description='Applicants must have a certified full time job offer from a Nova Scotia employer before they can apply to the provincial nominee program'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'nova-scotia',title:'Nova Scotia Provincial Nominee Program | Nova Scotia PNP | True Global' })
    this.meta.addTag({name:'nova-scotia', keyword:'Nova scotia provincial nominee program , nova scotia pnp, nova scotia immigration, nova scotia express entry, immigrate to nova scotia' })
    this.meta.addTag({name:'nova-scotia', description:'Applicants must have a certified full time job offer from a Nova Scotia employer before they can apply to the provincial nominee program'})
  }

}
