import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaqComponent } from './faq/faq.component';
import { ScholarshipsAndFinancialComponent } from './scholarships-and-financial/scholarships-and-financial.component';
import { AdmissionRequirementsComponent } from './admission-requirements/admission-requirements.component';
import { CostToStudyComponent } from './cost-to-study/cost-to-study.component';
import { StudyInCanadaComponent } from './study-in-canada/study-in-canada.component';
import { TopUniversitiesComponent } from './top-universities/top-universities.component';

const routes: Routes = [
  
  {path:'faq',component:FaqComponent},
  {path:'scholarships-and-financial',component:ScholarshipsAndFinancialComponent},
  {path:'admission-requirements',component:AdmissionRequirementsComponent},
  {path:'cost-to-study',component:CostToStudyComponent},
  {path:'study-in-canada',component:StudyInCanadaComponent},
  {path:'top-universities',component:TopUniversitiesComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VisaRoutingModule { }
