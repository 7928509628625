import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopHeaderComponent } from './menu/top-header/top-header.component';
import { BottomHeaderComponent } from './menu/bottom-header/bottom-header.component';
import { BannerComponent } from './home/banner/banner.component';
import { ContentTextComponent } from './home/content-text/content-text.component';
import { HomeSliderComponent } from './home/home-slider/home-slider.component';
import { HomeServiceComponent } from './home/home-service/home-service.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PopularComponent } from './home/popular/popular.component';
import { VideoSliderComponent } from './home/video-slider/video-slider.component';
import { PlanLifeComponent } from './home/plan-life/plan-life.component';
import { SlickModule } from 'ngx-slick';
import { AlertComponent } from './home/alert/alert.component';
import { FooterComponent } from './footer/footer.component';
import { HomeFormComponent } from './home/home-form/home-form.component';
import { MobileNavComponent } from './home/mobile-nav/mobile-nav.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CanadaModule } from './canada/canada.module';

import { ContactUsComponent } from './pages/contact-us/contact-us.component';

import { VisaModule } from './visa/visa.module';
import { ContactComponent } from './contact/contact.component';
import { BenefitsComponent } from './home/benefits/benefits.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';
import { RefundPolicyComponent } from './pages/refund-policy/refund-policy.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { PupupFormComponent } from './pages/pupup-form/pupup-form.component';
import { CanadaComponent } from './canada.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { ThanksComponent } from './thanks/thanks.component';
import { WorkVisaComponent } from './work-visa/work-visa.component';
import { VisitVisaComponent } from './visit-visa/visit-visa.component';
import { SuperVisaComponent } from './super-visa/super-visa.component';
import { StartupVisaComponent } from './startup-visa/startup-visa.component';
import { SettlementServiceComponent } from './settlement-service/settlement-service.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { SponsorshipVisaCanadaComponent } from './sponsorship-visa-canada/sponsorship-visa-canada.component';
import { ExpressEntryComponent } from './express-entry/express-entry.component';

@NgModule({
  declarations: [
    AppComponent,
    TopHeaderComponent,
    BottomHeaderComponent,
    BannerComponent,
    ContentTextComponent,
    HomeSliderComponent,
    HomeServiceComponent,
    PopularComponent,
    VideoSliderComponent,
    PlanLifeComponent,
    AlertComponent,
    FooterComponent,
    HomeFormComponent,
    MobileNavComponent,
    AboutUsComponent,
    ContactUsComponent,
    ContactComponent,
    BenefitsComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    RefundPolicyComponent,
    DisclaimerComponent,
    PupupFormComponent,
    CanadaComponent,
    SitemapComponent,
    ThanksComponent,
    WorkVisaComponent,
    VisitVisaComponent,
    SuperVisaComponent,
    StartupVisaComponent,
    SettlementServiceComponent,
    AssessmentComponent,
    SponsorshipVisaCanadaComponent,
    ExpressEntryComponent,
   
    
    
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    SlickModule,
    CanadaModule,
    SlickCarouselModule,

    VisaModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
