import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  title ='Immigration Advisor In Canada – True Global '
  keyword='Immigration advisor in canada, Immigration services in Toronto, canada express entry program, best immigration consultancy in Toronto, true global immigration, visa services in Toronto'
  description='True Global is a registered Canada immigration consultant in Canada with exceptional success rate. It’s also the best Immigration Advisor in Canada.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'Home', title:' Immigration Advisor In Canada – True Global ' })
    this.meta.addTag({name:'Home', keyword:'Immigration advisor in canada, Immigration services in Toronto, canada express entry program, best immigration consultancy in Toronto, true global immigration, visa services in Toronto' })
    this.meta.addTag({name:'Home', description:'True Global is a registered Canada immigration consultant in Canada with exceptional success rate. It’s also the best Immigration Advisor in Canada.'})
    // this.meta.updateTag({ property: 'og:url', content: `australia/australia-skilled-nominated-visa}` });
  }

}