import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  title ='Best Immigration Consultant – True Global'
  keyword='Best immigration Consultant , immigration advisor in canada , canada express entry program , immigrate to canada'
  description='True Global Immigration and Settlement Services Corp. was formed with the aim of providing pre and post immigration services to those wishing to immigrate to Canada. '
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'Home', title:'Best Immigration Consultant – True Global' })
    this.meta.addTag({name:'Home', keyword:'Best immigration Consultant , immigration advisor in canada , canada express entry program , immigrate to canada' })
    this.meta.addTag({name:'Home', description:'True Global Immigration and Settlement Services Corp. was formed with the aim of providing pre and post immigration services to those wishing to immigrate to Canada. '})
  }


}
