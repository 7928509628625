import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-northwest-territories-provincial-nominee-program',
  templateUrl: './northwest-territories-provincial-nominee-program.component.html',
  styleUrls: ['./northwest-territories-provincial-nominee-program.component.css']
})
export class NorthwestTerritoriesProvincialNomineeProgramComponent implements OnInit {
 
    title='Northwest Territories Provincial Nominee Program | True Global'
    keyword='Northwest territories provincial nominee program, northwest territories pnp, ntnp, northwest territories'
    description='Want to Apply for the Permanent Residency Visa for Canada through the Northwest Territories PNP and Northwest Territories Express Entry.'
    constructor(
      public router:Router,
      private titleService:Title,
      private meta:Meta,
    ) { }
  
    ngOnInit() {
  
      this.titleService.setTitle(this.title);
      this.meta.addTag({name:'northwest-territories-provincial-nominee-program',title:'Northwest Territories Provincial Nominee Program | True Global' })
      this.meta.addTag({name:'northwest-territories-provincial-nominee-program', keyword:'Northwest territories provincial nominee program, northwest territories pnp, ntnp, northwest territories' })
      this.meta.addTag({name:'northwest-territories-provincial-nominee-program', description:'Want to Apply for the Permanent Residency Visa for Canada through the Northwest Territories PNP and Northwest Territories Express Entry.'})
    }
  
  }
