import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-ontario-immigrant-nominee-program',
  templateUrl: './ontario-immigrant-nominee-program.component.html',
  styleUrls: ['./ontario-immigrant-nominee-program.component.css']
})
export class OntarioImmigrantNomineeProgramComponent implements OnInit {

  title ='Ontario Immigrant Nominee Program | OINP | True Global'
  keyword='Ontario immigrant nominee program, oinp, Ontario pnp, Ontario express entry, Ontario immigration'
  description='One of the most popular province of Canada is Ontario. The Ontario PNP is planned to invite prospective immigrants who are capable to drive the economic progress.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'ontario-immigrant-nominee-program',title:'Ontario Immigrant Nominee Program | OINP | True Global' })
    this.meta.addTag({name:'ontario-immigrant-nominee-program', keyword:'Ontario immigrant nominee program, oinp, Ontario pnp, Ontario express entry, Ontario immigration' })
    this.meta.addTag({name:'ontario-immigrant-nominee-program', description:'One of the most popular province of Canada is Ontario. The Ontario PNP is planned to invite prospective immigrants who are capable to drive the economic progress.'})
  }
}


// Provincial Nominee Program Canada |PNP Nomination| Canada Provincial Nomination