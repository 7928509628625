import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-provincial-nominee-program',
  templateUrl: './provincial-nominee-program.component.html',
  styleUrls: ['./provincial-nominee-program.component.css']
})
export class ProvincialNomineeProgramComponent implements OnInit {

  title ='Provincial Nominee Program Canada |PNP Nomination| Canada Provincial Nomination'
  keyword='- provincial nominee program Canada, provincial nominee program, PNP nomination, Canada provincial nomination'
  description='Canada provincial nomination - The Provincial Nominee Program (PNPs) Canada allows individual candidates to apply for PR in a particular province through PNP nomination.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'provincial-nominee-program',title:'Provincial Nominee Program Canada |PNP Nomination| Canada Provincial Nomination' })
    this.meta.addTag({name:'provincial-nominee-program', keyword:'- provincial nominee program Canada, provincial nominee program, PNP nomination, Canada provincial nomination' })
    this.meta.addTag({name:'provincial-nominee-program', description:'Canada provincial nomination - The Provincial Nominee Program (PNPs) Canada allows individual candidates to apply for PR in a particular province through PNP nomination.'})
  }
}


// Provincial Nominee Program Canada |PNP Nomination| Canada Provincial Nomination