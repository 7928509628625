import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admission-requirements',
  templateUrl: './admission-requirements.component.html',
  styleUrls: ['./admission-requirements.component.css']
})
export class AdmissionRequirementsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
