import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisaRoutingModule } from './visa-routing.module';
import { FaqComponent } from './faq/faq.component';
import { ScholarshipsAndFinancialComponent } from './scholarships-and-financial/scholarships-and-financial.component';
import { AdmissionRequirementsComponent } from './admission-requirements/admission-requirements.component';
import { CostToStudyComponent } from './cost-to-study/cost-to-study.component';
import { StudyInCanadaComponent } from './study-in-canada/study-in-canada.component';
import { TopUniversitiesComponent } from './top-universities/top-universities.component';


@NgModule({
  declarations: [
    FaqComponent, ScholarshipsAndFinancialComponent, AdmissionRequirementsComponent, CostToStudyComponent, StudyInCanadaComponent, TopUniversitiesComponent],
  imports: [
    CommonModule,
    VisaRoutingModule
  ]
})
export class VisaModule { }
