import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  title='Immigration Office | True Global Immigration'
  keyword='Immigration advisor in canada, express entry in canada, immigration experts, immigration services, visa consultancy services'
  description='Contact us for your immigration plan to overseas. Just the enquiry form to fins out your immigration eligibility.'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'contact',title:'Immigration Office | True Global Immigration' })
    this.meta.addTag({name:'contact', keyword:'Immigration advisor in canada, express entry in canada, immigration experts, immigration services, visa consultancy services' })
    this.meta.addTag({name:'contact', description:'Contact us for your immigration plan to overseas. Just the enquiry form to fins out your immigration eligibility.'})
  }

}